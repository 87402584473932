import React from "react"
import {
  faTwitterSquare,
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"

// importing components
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// importing assets
import "./footer.scss"

const Footer = props => (
  <div className="footer">
    <div className="container">
      <div className="upper-row rowToCol">
        <div className="footer-logo-address rowToCol">
          <div className="logo">
            <img src={props.logo} alt="logo"></img>
          </div>
          <div className="address">
            <h3>Head Office</h3>
            <p>
              111/112/113, Mahatma Phule Peth,
              <br />
              Near Dakshinmukhi Maruti,
              <br />
              Pune- 411 042 MH IN
            </p>
          </div>
        </div>
        <div className="quick-links">
          <h3>Quick Links</h3>
          <Link to="/products">Products</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="lower-row rowToCol">
        <div className="copyright-text">
          <p>© 2016 - {new Date().getFullYear()} Aquis India</p>
        </div>
        <div className="social-logos">
          <a href="" target="_blank">
            <FontAwesomeIcon icon={faTwitterSquare} size="sm" />
          </a>
          <a href="https://www.facebook.com/" target="_blank">
            <FontAwesomeIcon icon={faFacebookSquare} size="sm" />
          </a>
          <a href="" target="_blank">
            <FontAwesomeIcon icon={faInstagramSquare} size="sm" />
          </a>
          <a href="" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} size="sm" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
