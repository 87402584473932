import React from "react"

// import assets
import "./backdrop.scss"

const Backdrop = props => {
  return <div className="backdrop" onClick={props.click}></div>
}

export default Backdrop
