import React, { Component } from "react"

// import components
import HamburgerMenu from "./hamburgerMenu"

// importing assets
import "./hamburger.scss"

class Hamburger extends Component {
  state = {
    isOpen: false,
  }

  drawerClickHandler = () => {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen }
    })
  }

  render() {
    return (
      <>
        <div className="hamburger" onClick={this.drawerClickHandler}>
          <div className="hamburger__icon-line"></div>
          <div className="hamburger__icon-line"></div>
          <div className="hamburger__icon-line"></div>
        </div>

        <HamburgerMenu
          click={this.drawerClickHandler}
          isOpen={this.state.isOpen}
        />
      </>
    )
  }
}

export default Hamburger
