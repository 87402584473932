import React from "react"
import { Link } from "gatsby"

// import components
import Backdrop from "../../Backdrop/backdrop"

// import assets
import "./hamburger-menu.scss"

const HamburgerMenu = props => {
  let hamburgerMenuClasses = "hamburger-menu "

  if (props.isOpen) {
    hamburgerMenuClasses = "hamburger-menu menuOpen"
  }

  return (
    <div className="hamburgerMenuWrapper">
      <div className={hamburgerMenuClasses}>
        <nav className="hamburger-menu__nav-items">
          <Link to="/products">Products</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
      {props.isOpen ? <Backdrop click={props.click} /> : null}
    </div>
  )
}

export default HamburgerMenu
