import React from "react"

// importing components
import { Link } from "gatsby"

// Importing assets
import "./header.scss"
import Hamburger from "./Hamburger/hamburger"

const Header = props => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "activeLink" } : {}
  }

  return (
    <>
      <header>
        <div className="container">
          <div className="inner-header">
            <div className="logo">
              <Link to="/">
                <img src={props.logo} alt="logo"></img>
              </Link>
            </div>
            <div className="navigation">
              <nav className="nav-items">
                <Link to="/products" getProps={isPartiallyActive}>
                  Products
                </Link>
                <Link to="/about" getProps={isPartiallyActive}>
                  About
                </Link>
                <Link to="/contact" getProps={isPartiallyActive}>
                  Contact
                </Link>
              </nav>
              <Hamburger />
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
